import React, { Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Loading from './components/Loading';

const AdminApp = lazy(() => import('./admin/App'));
const PublicApp = lazy(() => import('./Main'));

const App = () => {
  return (
    <>
      <Router basename="">
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/admin" component={AdminApp} />
            <Route path="/" component={PublicApp} />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default App;
